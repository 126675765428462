export const GRID = 'Grid';
export const BANNER_CAROUSEL = 'BannerCarousel';
export const FOCUS_CAROUSEL = 'FocusedCarousel';
export const FOCUS_CAROUSEL_NEW = 'FocusedCarouselNew';
export const PRODUCT_GRID = 'ProductGrid';
export const PRODUCT_CAROUSEL = 'ProductCarousel';
export const WIDGET_FREE_SHIPPING = 'FreeShipping';
export const WIDGET_IMAGE_TILE = 'ImageTile';
export const CATEGORY_CAROUSEL = 'CategoryCarousel';
export const IMAGE_CAROUSEL = 'ImageCarousel';
export const WIDGET_VIDEO = 'Video';
export const RECENTLY_VIEWED = 'RecomRecentlyViewed';
export const VIDEO_GRID = 'VideoGrid';
export const MULTI_TAB_PRODUCT = 'MultiTabProducts';
export const AUTO_CAROUSEL = 'BannerGridCarousel';
