import { getStoreURL } from 'utils/';
import {
  API_DOMAIN,
  DOMAIN,
  USE_MICROSERVICE,
  MICROSERVICE_DOMAIN,
  BUCKET_URL,
  RECOMMENDATION_DOMAIN,
  ADDRESS_MAPPER_DOMAIN,
  ELASTIC_BASE_URL,
  MULIN_BASE_URL,
  CLICKSTREAM_DOMAIN,
  CLICK_STREAM_RECOMM_URL,
  PAYMENT_SERVICE_DOMAIN
} from '.';

export const PROXY_DOMAIN = API_DOMAIN;
export const AUTH_PROXY_DOMAIN = API_DOMAIN;

const QUOTE_BASE_URL = `${MICROSERVICE_DOMAIN}/rest/quote`;

// const QUOTE_BASE_URL = `http://localhost:5000/rest/quote`;
const ORDER_BASE_URL = `${MICROSERVICE_DOMAIN}/rest/order`;
const CUSTOMER_BASE_URL = `${MICROSERVICE_DOMAIN}/rest/customer`;
const CATALOG_BASE_URL = `${MICROSERVICE_DOMAIN}/rest/catalog`;

export const TAGALYSIS_ENABLED = false;

export const GET_AUTO_COMPLETE = () =>
  `${getStoreURL(ELASTIC_BASE_URL)}/api/v2/autocomplete`;

export const FETCH_PAYMENT_CONFIGS = () =>
  `${getStoreURL(PAYMENT_SERVICE_DOMAIN)}/v1/payment/configs`;

/* Customer service enpoints  */

export const PUT_ITEM_VIEW_DETAILS = () => `${CLICKSTREAM_DOMAIN}/event_log`;

export const GET_CLICK_STREAM_RECOMM_URL = () =>
  `${CLICK_STREAM_RECOMM_URL}/api/v1/clickstream/user_recommendation`;

export const GET_WISHLIST = ({ customerId, storeId }) =>
  USE_MICROSERVICE
    ? `${CUSTOMER_BASE_URL}/auth/v5/wishlist/view`
    : `${AUTH_PROXY_DOMAIN}/rest/auth/customer/wishlist/v4.1/get/customerId/${customerId}/store/${storeId}`;

export const GET_WISHLIST_IDS = ({ customerId, storeId }) =>
  USE_MICROSERVICE
    ? `${CUSTOMER_BASE_URL}/auth/v4.1/wishlist/ids/customerId/${customerId}/store/${storeId}`
    : `${AUTH_PROXY_DOMAIN}/rest/auth/customer/wishlist/v4.1/ids/customerId/${customerId}/store/${storeId}`;

export const UPDATE_WISHLIST = () =>
  USE_MICROSERVICE
    ? `${CUSTOMER_BASE_URL}/auth/v4.1/wishlist`
    : `${AUTH_PROXY_DOMAIN}/rest/auth/customer/wishlist/v4.1`;

export const GET_SELECTED_ADDRESS = ({ addressId, customerId }) =>
  USE_MICROSERVICE
    ? `${CUSTOMER_BASE_URL}/auth/address/addressid/${addressId}/customerid/${customerId}`
    : `${AUTH_PROXY_DOMAIN}/rest/auth/customer/address/${addressId}/customer/${customerId}`;

export const GET_ADDRESS_LIST = ({ customerId }) =>
  USE_MICROSERVICE
    ? `${CUSTOMER_BASE_URL}/auth/address/v2/view`
    : `${AUTH_PROXY_DOMAIN}/rest/auth/customer/address/${customerId}`;

export const GET_PROFILE = ({ customerId }) =>
  USE_MICROSERVICE
    ? `${CUSTOMER_BASE_URL}/auth/v2/profile`
    : `${AUTH_PROXY_DOMAIN}/rest/auth/customer/profile/${customerId}`;
export const DELETE_ELIGIBLE_CUSTOMER_API = () =>
  USE_MICROSERVICE
    ? `${CUSTOMER_BASE_URL}/auth/delete/eligible`
    : `${AUTH_PROXY_DOMAIN}/rest/auth/delete/eligible`;
export const GET_SAVED_CARD_LIST = ({ customerId }) =>
  USE_MICROSERVICE
    ? `${CUSTOMER_BASE_URL}/auth/v2/card`
    : `${AUTH_PROXY_DOMAIN}/rest/auth/customer/card/customer/${customerId}`;

export const DELETE_CARD_API = () =>
  USE_MICROSERVICE
    ? `${CUSTOMER_BASE_URL}/auth/customer/card `
    : `${AUTH_PROXY_DOMAIN}/rest/auth/order/card/`;

export const API_JWT_FROM_EMAIL = () =>
  USE_MICROSERVICE
    ? `${CUSTOMER_BASE_URL}/token/create`
    : `${PROXY_DOMAIN}/rest/token/create`;

export const SAVE_ADDRESS_API = () =>
  USE_MICROSERVICE
    ? `${CUSTOMER_BASE_URL}/auth/address`
    : `${AUTH_PROXY_DOMAIN}/rest/auth/customer/address`;

export const DELETE_ADDRESS_API = () =>
  USE_MICROSERVICE
    ? `${CUSTOMER_BASE_URL}/auth/address`
    : `${AUTH_PROXY_DOMAIN}/rest/auth/customer/address`;

export const LOGIN_VALIDATE = () =>
  USE_MICROSERVICE
    ? `${CUSTOMER_BASE_URL}/validate`
    : `${PROXY_DOMAIN}/rest/customer/validate`;

export const PROFILE_UPDATE_API = () =>
  USE_MICROSERVICE
    ? `${CUSTOMER_BASE_URL}/auth/profile/update`
    : `${AUTH_PROXY_DOMAIN}/rest/auth/customer/update/profile`;

export const LOGIN = () =>
  USE_MICROSERVICE
    ? `${CUSTOMER_BASE_URL}/v4/login`
    : `${PROXY_DOMAIN}/rest/customer/v4/login`;

export const API_WHATSAPP_OPT = () =>
  USE_MICROSERVICE
    ? `${CUSTOMER_BASE_URL}/auth/config/whatsaap/opt`
    : `${AUTH_PROXY_DOMAIN}/rest/auth/config/whatsaap/opt`;

export const FORGOT_PASSWORD = () =>
  USE_MICROSERVICE
    ? `${CUSTOMER_BASE_URL}/forget/password`
    : `${PROXY_DOMAIN}/rest/customer/forget/password`;

export const RESET_PASSWORD = () =>
  USE_MICROSERVICE
    ? `${CUSTOMER_BASE_URL}/auth/reset/password`
    : `${AUTH_PROXY_DOMAIN}/rest/auth/customer/reset/password`;

export const CREATE_PASSWORD = () =>
  `${CUSTOMER_BASE_URL}/reset/token/password`;

export const REGISTER = () =>
  USE_MICROSERVICE
    ? `${CUSTOMER_BASE_URL}/v4/registration`
    : `${PROXY_DOMAIN}/rest/customer/v4/registration`;

/* Utility service enpoints  */
export const GET_CATEGORY_DATA = ({ storeId }) =>
  // USE_MICROSERVICE
  //   ? `${UTILS_BASE_URL}/categories/store/${storeId}`
  // : `${PROXY_DOMAIN}/rest/categories/store/${storeId}`;

  USE_MICROSERVICE
    ? `${BUCKET_URL}/category-response-${storeId}.json`
    : `${PROXY_DOMAIN}/rest/categories/store/${storeId}`;

export const GET_CONFIG = () =>
  USE_MICROSERVICE
    ? `${BUCKET_URL}/all-store-config-response.json`
    : `${PROXY_DOMAIN}/rest/config/v1`;

// USE_MICROSERVICE
//   ? `${UTILS_BASE_URL}/config/v1`
//   : `${PROXY_DOMAIN}/rest/config/v1`;

/* Quote service enpoints  */
export const GET_TOTALS_API = () => `${QUOTE_BASE_URL}/auth/v6/get/totals`;

export const SELECT_ADDRESS_API = () =>
  USE_MICROSERVICE
    ? `${QUOTE_BASE_URL}/auth/v5/address`
    : `${AUTH_PROXY_DOMAIN}/rest/auth/quote/v4/address`;

export const GET_QUOTE_API = () =>
  USE_MICROSERVICE
    ? `${QUOTE_BASE_URL}/auth/v6/get`
    : `${AUTH_PROXY_DOMAIN}/rest/auth/quote/v4/get`;

export const MIGRATE_QUOTE_API = () =>
  USE_MICROSERVICE
    ? `${QUOTE_BASE_URL}/auth/v5/migrate`
    : `${AUTH_PROXY_DOMAIN}/rest/auth/quote/v4/migrate`;

export const MANAGE_QUOTE_API = () =>
  USE_MICROSERVICE
    ? `${QUOTE_BASE_URL}/auth/v5`
    : `${AUTH_PROXY_DOMAIN}/rest/auth/quote/v4`;

export const VALIDATE_QUOTE_API = () =>
  USE_MICROSERVICE ? `${QUOTE_BASE_URL}/auth/v5/view/validate` : ``;

export const GET_QUOTE_METADATA_API = () =>
  USE_MICROSERVICE ? `${QUOTE_BASE_URL}/auth/v5/view/metadata` : ``;

export const API_QUOTE_CHANGE_SIZE = () =>
  USE_MICROSERVICE
    ? `${QUOTE_BASE_URL}/auth/v5/changesize`
    : `${AUTH_PROXY_DOMAIN}/rest/auth/quote/v4/changesize`;

export const API_STORE_CREDIT_UPDATE = () =>
  USE_MICROSERVICE
    ? `${QUOTE_BASE_URL}/auth/v5/storecredit`
    : `${AUTH_PROXY_DOMAIN}/rest/auth/quote/v4/storecredit`;

export const API_APPLY_COUPON = () =>
  USE_MICROSERVICE
    ? `${QUOTE_BASE_URL}/auth/v5/coupon`
    : `${AUTH_PROXY_DOMAIN}/rest/auth/quote/v4/coupon`;

export const API_CHANGE_PAYMENT_METHOD = () =>
  USE_MICROSERVICE
    ? `${QUOTE_BASE_URL}/auth/v5/payment`
    : `${AUTH_PROXY_DOMAIN}/rest/auth/quote/v4/payment`;

export const API_GET_BAG_COUNT = () =>
  USE_MICROSERVICE
    ? `${QUOTE_BASE_URL}/auth/v5/view/count`
    : `${AUTH_PROXY_DOMAIN}/rest/auth/quote/v4/view/count`;

/* Order service enpoints  */

export const API_CREATE_ORDER = () =>
  USE_MICROSERVICE
    ? `${ORDER_BASE_URL}/auth/v2/create`
    : `${AUTH_PROXY_DOMAIN}/rest/auth/order/create`;

export const QUOTE_REPLICA = () =>
  USE_MICROSERVICE
    ? `${ORDER_BASE_URL}/auth/v2/replica`
    : `${AUTH_PROXY_DOMAIN}/rest/auth/quote/v4/create/replica`;

export const QUOTE_REPLICA_HO = () =>
  `${ORDER_BASE_URL}/auth/v2/retry-payment/replica`;

export const API_RE_ORDER = () =>
  USE_MICROSERVICE
    ? `${ORDER_BASE_URL}/auth/v2/reorder`
    : `${AUTH_PROXY_DOMAIN}/rest/auth/order/reorder`;

export const API_RETRY_PAYMENT = () =>
  USE_MICROSERVICE
    ? `${ORDER_BASE_URL}/auth/v2/retrypayment`
    : `${AUTH_PROXY_DOMAIN}/rest/auth/order/retrypayment`;

export const API_ORDER_EMAIL = () =>
  USE_MICROSERVICE
    ? `${ORDER_BASE_URL}/rest/order/auth/email`
    : `${AUTH_PROXY_DOMAIN}/rest/auth/order/email`;

export const API_GET_ORDER = ({ orderId, storeId }) =>
  USE_MICROSERVICE
    ? `${ORDER_BASE_URL}/auth/orderid/${orderId}/storeid/${storeId}`
    : `${AUTH_PROXY_DOMAIN}/rest/auth/order/${orderId}/store/${storeId}`;

export const API_GET_ORDER_LISTING = () =>
  USE_MICROSERVICE
    ? `${ORDER_BASE_URL}/auth/list`
    : `${AUTH_PROXY_DOMAIN}/rest/auth/order/view/list/all`;

export const API_GET_UNPAID_ORDER_LISTING = () =>
  USE_MICROSERVICE
    ? `${ORDER_BASE_URL}/auth/unpaid/list`
    : `${AUTH_PROXY_DOMAIN}/rest/auth/order/view/list/unpaid`;

export const API_GET_RETURN_ORDER_LISTING = () =>
  USE_MICROSERVICE
    ? `${ORDER_BASE_URL}/auth/return/list`
    : `${AUTH_PROXY_DOMAIN}/rest/auth/order/view/list/returns`;

export const API_GET_RETURN_COUNT = () => `${ORDER_BASE_URL}/auth/return/count`;

// export const API_GET_RETURN_ORDER_LISTING = () => `/returnListResponse.json`;

export const API_GET_ORDER_COUNT = () =>
  USE_MICROSERVICE
    ? `${ORDER_BASE_URL}/auth/count`
    : `${AUTH_PROXY_DOMAIN}/rest/auth/order/view/count/all`;

export const API_CANCEL_ORDER = () =>
  USE_MICROSERVICE
    ? `${ORDER_BASE_URL}/auth/cancel`
    : `${AUTH_PROXY_DOMAIN}/rest/auth/order/cancel`;

export const API_CANCEL_ORDER_INIT = () =>
  USE_MICROSERVICE
    ? `${ORDER_BASE_URL}/auth/cancel/init`
    : `${AUTH_PROXY_DOMAIN}/rest/auth/order/cancel/init`;

export const API_RETURN_ORDER = () =>
  USE_MICROSERVICE
    ? `${ORDER_BASE_URL}/auth/v2/rma`
    : `${AUTH_PROXY_DOMAIN}/rest/auth/order/rma/v2`;

export const API_GET_COUPON_LISTING = () =>
  USE_MICROSERVICE
    ? `${ORDER_BASE_URL}/auth/coupons/list`
    : `${AUTH_PROXY_DOMAIN}/rest/auth/order/view/list/promo`;

export const API_GET_CREDIT_HISTORY = () =>
  USE_MICROSERVICE
    ? `${ORDER_BASE_URL}/auth/storecredit/history`
    : `${AUTH_PROXY_DOMAIN}/rest/auth/order/view/list/storecredit`;

// export const API_PRODUCT_LISTING = ({ contextRuleId }) =>
//   USE_MICROSERVICE ? `` :  `${PROXY_DOMAIN}/rest/product/${TAGALYSIS_ENABLED ? 'V3' : 'V2'}/listing${
//     contextRuleId ? '/vm' : ''
//   }`;

export const API_INVENTORY_CHECK = () =>
  USE_MICROSERVICE
    ? `${CUSTOMER_BASE_URL}/auth/inventory/status`
    : `${PROXY_DOMAIN}/rest/product/inventory/status`;
export const API_PRODUCT_LISTING = ({ contextRuleId }) =>
  USE_MICROSERVICE
    ? `${CATALOG_BASE_URL}/v2/listing/vm`
    : `${AUTH_PROXY_DOMAIN}/rest/product/${
        TAGALYSIS_ENABLED ? 'V3' : 'V2'
      }/listing${contextRuleId ? '/vm' : ''}`;
export const API_PRODUCT_LISTING_NODE = ({ reqUrl }) =>
  `${getStoreURL(reqUrl)}`;

export const API_PRODUCT_DETAILS_BY_SKU = () =>
  USE_MICROSERVICE
    ? `${CATALOG_BASE_URL}/v5/get/list`
    : `${PROXY_DOMAIN}/rest/product/v4/get/list`;

export const API_PRODUCT_DETAILS_BY_SKU_CUROFY = ({ reqPDPListUrl }) =>
  `${getStoreURL(reqPDPListUrl)}`;

export const API_PRODUCT_DETAILS_BY_ID = () =>
  USE_MICROSERVICE
    ? `${CATALOG_BASE_URL}/v5/get`
    : `${PROXY_DOMAIN}/rest/product/v4/get`;

export const API_PRODUCT_DETAILS_BY_ID_CUROFY = ({ reqPDPUrl }) =>
  `${getStoreURL(reqPDPUrl)}`;

export const API_BEAUTY_PRODUCT_INFO = () =>
  `${CUSTOMER_BASE_URL}/product/info`;

export const GET_PROMO_DETAILS = ({ productId, storeId }) =>
  USE_MICROSERVICE
    ? `${CATALOG_BASE_URL}/{productId}/promotions/store/{storeId}`
    : `${PROXY_DOMAIN}/rest/product/${productId}/promotions/store/${storeId}`;

export const GET_PRODUCT_METADATA = () => `${CATALOG_BASE_URL}/metadata`;
// USE_MICROSERVICE ? `` : `${PROXY_DOMAIN}/rest/product/metadata`;

export const GET_ORDER_DETAILS = () =>
  USE_MICROSERVICE
    ? `${ORDER_BASE_URL}/auth/view`
    : `${AUTH_PROXY_DOMAIN}/rest/auth/order/view/`;

export const GET_PAYABLE_ORDERS = () => `${ORDER_BASE_URL}/auth/payable/view`;

export const GET_TABBY_ORDER_DETAILS = ({ paymentId }) =>
  `${ORDER_BASE_URL}/auth/paymentId/${paymentId}`;

export const GET_RATE_DETAILS = () =>
  USE_MICROSERVICE
    ? `${ORDER_BASE_URL}/ratings/list`
    : `${AUTH_PROXY_DOMAIN}/rest/auth/order/ratings/list`;

export const SAVE_RATE_DETAILS = () =>
  USE_MICROSERVICE
    ? `${ORDER_BASE_URL}/ratings/update`
    : `${AUTH_PROXY_DOMAIN}/rest/auth/order/ratings/update`;

export const GET_INIT_RMA = () =>
  USE_MICROSERVICE
    ? `${ORDER_BASE_URL}/auth/v2/rma/init`
    : `${AUTH_PROXY_DOMAIN}/rest/auth/order/rma/v2/init`;

// export const GET_INIT_RMA = () => `/returnInit.json`;

export const API_CANCEL_RMA = () =>
  USE_MICROSERVICE
    ? `${ORDER_BASE_URL}/auth/cancel`
    : `${AUTH_PROXY_DOMAIN}/rest/auth/order/rma/cancel`;

export const GET_STORE_CREDIT = ({ customerId }) =>
  USE_MICROSERVICE
    ? `${ORDER_BASE_URL}/auth/v2/storecredit`
    : `${AUTH_PROXY_DOMAIN}/rest/auth/customer/storecredit/${customerId}`;

export const GET_CATEGORY_URL_PATH = () =>
  USE_MICROSERVICE
    ? `${CATALOG_BASE_URL}/category/urlpath`
    : `${PROXY_DOMAIN}/rest/category/urlpath`;

export const CLICKPOST_TRACKING_URL =
  process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'qa'
    ? 'https://stylishop-test.clickpost.in/'
    : 'https://stylishop.clickpost.in/';

// export const GET_BANK_OFFERS = () => '/bankOffers.json'
export const GET_BANK_OFFERS = () => `${ORDER_BASE_URL}/auth/coupons/bank/list`;

export const GET_ALSO_BOUGHT = () =>
  `${CATALOG_BASE_URL}/recommendation/v1/also-bought-items`;
export const GET_YOU_MAY_LIKE = () =>
  `${CATALOG_BASE_URL}/recommendation/v1/you-may-also-like`;
export const GET_RECENTLY_VIEWED = () =>
  `${RECOMMENDATION_DOMAIN}/api/v1/recently_viewed`;
export const GET_ALSO_BOUGHT_MIX = () =>
  `${CATALOG_BASE_URL}/recommendation/v1/also-bought-mix-category-items`;
// https://recommendation-qa.stylishop.store/api/v2/you_may_also_like_count
// export const GET_AUTO_COMPLETE = () =>
//   `${ELASTIC_BASE_URL}/api/v2/autocomplete`;
export const GET_YOU_MAY_LIKE_COUNT = () =>
  `${RECOMMENDATION_DOMAIN}/api/v2/you_may_also_like_count`;
// oosRecommendationCountUrl
// export const GET_YOU_MAY_LIKE = () => '/youMayLike.json'
// export const GET_ALSO_BOUGHT = () => '/youMayLike.json'

export const GET_CITY_DETAILS = () => {
  return `${getStoreURL(ADDRESS_MAPPER_DOMAIN)}/api/address/search/city`;
};

export const GET_SEND_OTP = () => `${CUSTOMER_BASE_URL}/auth/send/otp`;
export const GET_VERIFY_OTP = () => `${CUSTOMER_BASE_URL}/validate/otp`;
export const GET_SEND_DELETE_OTP = () => `${CUSTOMER_BASE_URL}/auth/delete/send/otp`;

export const VERIFY_RECAPTCHA = () => `${CUSTOMER_BASE_URL}/recaptcha/verify`;
export const GET_PDP_RATINGS = () => `${MULIN_BASE_URL}/v1/ratings/pdpRatings`;

export const GET_MODEL_INFO = () => `${MULIN_BASE_URL}/v1/model/details`;

export const GET_BANK_NAMES_AND_SWIFT_CODES = () =>
  `${ORDER_BASE_URL}/oms/bank/mapper`;

export const CREATE_BANK_TRANSFER = () =>
  `${ORDER_BASE_URL}/auth/oms/bank/submit`;

export const GET_STRAPI_ROWS = () => `${DOMAIN}/plp-banners`;
export const DELETE_REQUEST = () =>
  USE_MICROSERVICE
    ? `${CUSTOMER_BASE_URL}/auth/delete/request`
    : `${PROXY_DOMAIN}/rest/customer/auth/delete/request`;
