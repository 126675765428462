import { getNavigationBasePath } from 'utils';
import { CURRENT_LANG_PREFIX } from '.';

export const ACCOUNT_PAGE_PROPERTIES = {
  orders: {
    title: 'My Orders',
    src: `${getNavigationBasePath()}/sales/order/history/`
  },
  credits: {
    title: 'Styli Credits',
    src: `${getNavigationBasePath()}/amstorecredit/`
  },
  profile: {
    title: 'My Profile',
    src: `${getNavigationBasePath()}/customer/account/edit/`
  },
  addresses: {
    title: 'My Addresses',
    src: `${getNavigationBasePath()}/customer/address/`
  },
  savedcards: {
    title: 'My Saved Cards',
    src: `${getNavigationBasePath()}/vault/cards/listaction/`
  },
  changepassword: {
    title: 'Change Password',
    src: `${getNavigationBasePath()}/customer/account/edit/`
  },
  language: {
    title: 'Language',
    src: `/${CURRENT_LANG_PREFIX === 'ar' ? 'en' : 'ar'}/`
  },
  changecountry: {
    title: 'Country',
    src: `/${CURRENT_LANG_PREFIX === 'ar' ? 'en' : 'ar'}/`
  },
  contactus: {
    title: 'Contact Us',
    src: `/${CURRENT_LANG_PREFIX}/contact/`
  },
  help: {
    title: 'Help',
    link: 'https://landmarkgroupaid.freshdesk.com/support/solutions'
  },
  whatsappus: {
    title: 'WhatsApp Us',
    src: `/${CURRENT_LANG_PREFIX}/contact/`
  },
  orderReturns: {
    title: 'Returns',
    src: `/${CURRENT_LANG_PREFIX}/rma/account/history/`
  },
  writetous: {
    title: 'Write to Us',
    src: `/${CURRENT_LANG_PREFIX}/contact/`
  },
  callsupport: {
    title: 'Contact Us',
    src: `/${CURRENT_LANG_PREFIX}/contact/`
  },
  privacy: {
    title: 'Privacy Policy',
    src: `${getNavigationBasePath()}/legal/privacy`
  },
  terms: {
    title: 'Terms of Use',
    src: `${getNavigationBasePath()}/legal/user-agreement`
  },
  faq: {
    title: 'FAQs',
    src: `${getNavigationBasePath()}/helpcentre`
  },
  returns: {
    title: 'Return Policy',
    src: `${getNavigationBasePath()}/helpcentre`,
    hash: 'returns'
  },
  promo: {
    title: 'Offer Terms',
    src: `${getNavigationBasePath()}/wfs/offers`,
    hash: 'promo'
  },
  login: {
    title: 'Log In / Sign Up',
    src: `${getNavigationBasePath()}/customer/account/useridentifications/`
  }
};

export default {};
