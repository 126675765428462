export const JWT_ERROR = ['400', '500'];
export const JWT_MSG = [
  "403 not found with Exception : 'JWT Token is not correct for given customer id!'",
  "403 not found with Exception : 'JWT Token is missing!'",
  'JWT Token is incorrect',
  "403 not found with Exception : 'JWT token is not matching for given user!'",
  'invalid signature',
  'jwt malformed',
  'invalid token'
  // "Invalid token specified: Cannot read property 'replace' of undefined"
];
