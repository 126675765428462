function getCookieValue(a) {
  const b = document.cookie.match(`(^|[^;]+)\\s*${a}\\s*=\\s*([^;]+)`);
  return b ? b.pop() : '';
}
function getDefaultLanguage(language) {
  let lang = language ? 'ar' : undefined;
  const cookieLanguage = getCookieValue('ln');
  if (!lang && ['ar', 'en'].indexOf(cookieLanguage) > -1) {
    lang = cookieLanguage;
  } else lang = 'ar';
  return lang;
}
function getCurrentLanguage() {
  const { pathname } = window.location;
  const [, country, language] = pathname.split('/');
  if (country.toLocaleLowerCase() === 'in') {
    return 'en';
  }
  let lang = language === 'en' ? 'en' : 'ar';
  if (['en', 'ar'].indexOf(language) > -1) {
    lang = language;
  } else {
    lang = getDefaultLanguage(language);
  }
  return lang;
}
export default getCurrentLanguage() === 'ar';
