import log from 'utils/log';
// import { IS_ARABIC } from '.';

import en from '../language/en.json';
import ar from '../language/ar.json';
import isArabic from './isArabic';

const currentLanguage = isArabic ? ar : en;

export const translate = key => {
  if (!currentLanguage[key] && currentLanguage !== en) {
    if (process.env.NODE_ENV === 'development') {
      log(key);
    }
  }
  return currentLanguage[key] || key;
};

export const UNKNOWN_ERROR = translate('Oops!! Something went Wrong');

export default {};
